import { createFeature, createReducer, on } from '@ngrx/store';
import { MyEmployeesState } from './my-employees.state';
import { MyEmployeesContainerActions } from './actions';

export const initialState: Readonly<MyEmployeesState> = {
  employees: [],
};

export const myEmployeesReducers = createReducer(
  initialState,
  on(MyEmployeesContainerActions.searchSuccess, (state, { data }) => ({
    ...state,
    employees: data,
  })),
);

export const myEmployeesFeature = createFeature({
  name: 'myEmployees',
  reducer: myEmployeesReducers,
});
