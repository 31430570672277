import { Injectable } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class EditEmployeeService {
  public readonly form = this.fb.group({
    employeeId: this.fb.control('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    firstname: this.fb.control('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    lastname: this.fb.control('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    email: this.fb.control('', [Validators.required, Validators.email]),
    telephone: this.fb.control('', [
      Validators.required,
      Validators.maxLength(15),
    ]),
    isGlobalAdmin: this.fb.control(false, []),
  });

  constructor(private fb: NonNullableFormBuilder) {}
}
