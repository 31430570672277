import { createAction, props } from '@ngrx/store';
import { Worker } from '../../../../core/models/app.models';

export const search = createAction(
  '[My Employees Container] Search',
  props<{ searchText: string }>(),
);
export const searchSuccess = createAction(
  '[My Employees Container] Search success',
  props<{ data: Worker[] }>(),
);
export const searchError = createAction(
  '[My Employees Container] Search error',
);

export const createEmployee = createAction(
  '[My Employees Container] Create Employee',
);
export const editEmployee = createAction(
  '[My Employees Container] Edit Employee',
  props<{ id: number }>(),
);
export const deleteEmployee = createAction(
  '[My Employees Container] Delete Employee',
  props<{ id: number }>(),
);
export const deleteClientSuccess = createAction(
  '[My Employees Container] Delete Employee success',
);
export const backClicked = createAction(
  '[My Employees Container] Back clicked',
);
export const clickedCreateEmployee = createAction(
  '[My Employees Container] Clicked Create Employee',
);
export const resetCreateForm = createAction(
  '[My Employees Container] Reset Create Form',
);
export const roleManagementClicked = createAction(
  '[My Employees Container] Role Management Clicked',
);
export const passwortResetClicked = createAction(
  '[My Employees Container] Passwort Reset Clicked',
  props<{ id: number }>(),
);
export const loadEmployee = createAction(
  '[My Employees Container] Load Employee',
  props<{ id: number }>(),
);
export const saveEditEmployee = createAction(
  '[My Employees Container] Save Edit Employee',
  props<{ id: number }>(),
);
export const editEmployeeRoleClicked = createAction(
  '[My Employees Container] Edit Employee Role Clicked',
  props<{ employeeId: string }>(),
);
