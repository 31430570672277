import { myEmployeesFeature } from './my-employees.reducers';
import { MyEmployeesContainerActions } from './actions';

const { reducer, name, ...myEmployeesSelectors } = myEmployeesFeature;

export {
  name as myEmployeesName,
  reducer as myEmployeesReducer,
  myEmployeesSelectors,
  MyEmployeesContainerActions,
};
